import React, { useCallback, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { checkAuthenticationAndRedirect } from '@britannica/platform-client-lib';
import history from './history';
import AppRoutes from './App.routes';
import myBritannicaApi from './services/MyBritannicaApi';
import AuthApi from './services/AuthApi';
import { individualAuthActions } from './pages/IndividualAuth/IndividualAuth.dux';
import { myBritannicaActions } from './pages/MyBritannicaPage/MyBritannica.dux';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import useWithStore from './hooks/useWithStore.hook';
import { instAuthActions } from './store/InstAuth/InstAuth.dux';
import { RoutePath } from './constants';
import RouteChange from './components/RouteChange/RouteChange';
import './App.scss';

const App = () => {
  const {
    store: { instAuth, individualAuth, collections },
    dispatch,
  } = useWithStore(
    state => ({
      instAuth: state.instAuth,
      individualAuth: state.individualAuth,
      collections: state.myBritannica.collections,
    }),
    {
      instAuthSuccess: instAuthActions.instAuthSuccess,
      instAuthFail: instAuthActions.instAuthFail,
      instAuthInitial: instAuthActions.instAuthInitial,
      individualAuthSuccess: individualAuthActions.individualAuthSuccess,
      individualAuthFail: individualAuthActions.individualAuthFail,
      individualAuthInitial: individualAuthActions.individualAuthInitial,
      setCollections: myBritannicaActions.setCollections,
    }
  );
  const isLoading = !instAuth.responseReturned;

  const isInstAuth = instAuth.isAuthenticated;
  const userData = instAuth.instUserData;

  const getIndividualUserAttributes = useCallback(async () => {
    try {
      const userAttributes = userData?.individual;

      if (!userAttributes) {
        dispatch.individualAuthFail({ status: 401, message: 'Unauthorized' });
      } else {
        dispatch.individualAuthSuccess(userAttributes);
      }
    } catch (error) {
      dispatch.individualAuthFail(error);
    }
  }, [dispatch, userData?.individual]);

  useEffect(() => {
    if (isLoading && window.location.href?.includes(RoutePath.SEARCH_WIDGET)) {
      dispatch.instAuthSuccess({});

      return;
    }

    if (isLoading && !userData && dispatch) {
      checkAuthenticationAndRedirect(
        isInstAuth,
        userData,
        'pt-BR',
        ['PORTK6', 'PORT6-8', 'PORT9-12'],
        user => {
          dispatch.instAuthSuccess(user);
        },
        () => {
          dispatch.instAuthFail({ status: 401, message: 'Unauthorized' });
        },
        // passing the func ref directly was causing errors when accessing the isAuth() func
        () => AuthApi.isAuth()
      );
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        Boat_ID: userData?.accountId,
        Individual_ID: userData?.individual?.individualId,
      });
    }
  }, [isLoading, isInstAuth, userData, dispatch]);

  // if the access token is available, try fetching for the user attributes
  useEffect(() => {
    if (!window.location.href?.includes('/deeplink/')) {
      const individualUserData = userData?.individual;

      if (individualUserData) {
        getIndividualUserAttributes();
      } else {
        // EMF-700, EMF-669
        // If there is no accessToken present in cookies dispatch the authInitial action
        // with the payload - { responseReturned: true } - setting the Redux state back to its
        // initial state with the only exception being that responseReturned is set to true.
        // We need to do this so that the applicable condition within the JSX in Header.js
        // is triggered.
        dispatch.individualAuthInitial({ responseReturned: true });
      }
    }
  }, [dispatch, getIndividualUserAttributes, userData?.individual]);

  // if the user is authenticated and collections is null, try to fetch for the collections.
  useEffect(() => {
    async function getCollections() {
      try {
        const collectionsResponse = await myBritannicaApi.getCollections();

        dispatch.setCollections(collectionsResponse);
      } catch (error) {
        dispatch.setCollections(error);
      }
    }

    if (
      individualAuth.isAuthenticated &&
      !collections &&
      !window.location.href?.includes('/deeplink/')
    ) {
      getCollections();
    }
  }, [individualAuth, collections, dispatch]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Router history={history}>
      <RouteChange />
      <AppRoutes />
    </Router>
  );
};

export default App;
